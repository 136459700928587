<template>
  <div class="grid grid-cols-12">
    <div class="col-span-1 md:col-span-6 2xl:col-span-6 h-screen min-h-full">
      <img class="h-full object-cover animate__animated  animate__fadeInLeft" :src="imagem" alt="">
    </div>
    <div class="col-span-11 md:col-span-6 2xl:col-span-6 p-10 2xl:p-32 pt-4 2xl:pt-4">
        
        <img v-if="logo" class="md:mt-16 mx-auto" :src="logo" width="200" />
        <img v-else class="md:mt-16 mx-auto" :src="require('../assets/logo.png')" width="200" />

        <p class="text-center text-2.5xl font-bold" :style="{ 'color': colorSecundaria }" >
          {{ texto }}
        </p>
        <p class="text-center text-2xl">
          acesse sua plataforma
        </p>

        <div class="p-0 2xl:p-20">
          <label class="font-semibold text-base text-gray-800 pb-2 block">Entre como:</label>
          <div class="grid grid-cols-12 mb-4 gap-1">
            <div @click="option = 'consultor'" class="col-span-12 2xl:col-span-6 text-center py-3 rounded-full cursor-pointer bg-yellow-100 " :class="option === 'consultor' ? 'text-center' :'opacity-50'" :style="{ 'background-color': colorSecundaria, 'color': invertColor(colorSecundaria, true) }">
              <label class="font-semibold text-sm">Profissional</label>
            </div>
            <div @click="option = 'pessoas'"   class="col-span-12 2xl:col-span-6 text-center py-3 rounded-full cursor-pointer bg-blue-100" :class="option === 'pessoas' ? 'text-center' :'opacity-50'" :style="{ 'background-color': colorPrincipal, 'color': invertColor(colorPrincipal, true) }">
              <label class="font-semibold text-sm">Convidado</label>
            </div>
          </div>
          <label class="font-semibold text-base text-gray-800 pb-2 block">Digite seu e-mail de login e senha</label>
          <input v-model="form.email" placeholder="E-mail" type="email" class="border-none bg-gray-200 focus:ring-gray-800 px-3 py-3 mt-1 mb-3 text-base w-full" />
          <div class="relative">
            <input v-model="form.password" placeholder="Senha" :type="visible ? 'text' : 'password'" class="border-none bg-gray-200 focus:ring-gray-800 px-3 py-3 mt-1 mb-5 text-base w-full">
            <svg v-if="!visible" @click="visible = !visible" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-800 absolute top-4 right-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
            </svg>
            <svg v-if="visible" @click="visible = !visible" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-800 absolute top-4 right-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
            </svg>
          </div>
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 mx-auto">
            
              <!-- <div class="g-recaptcha mx-auto" data-sitekey="6Lcn1d0pAAAAABXxaUhQbVSnHKv28J8j8ciuy_9F" id="recaptcha" ref="recaptcha"></div> -->
              <vue-recaptcha
                ref="recaptcha"
                sitekey="6Lcn1d0pAAAAABXxaUhQbVSnHKv28J8j8ciuy_9F"
                @verify="onCaptchaVerified"
                @expired="onCaptchaExpired"
              ></vue-recaptcha>
            </div>
            <div class="col-span-12 ">
              <button @click="login" type="button" class="mt-5 rounded-full transition duration-200 bg-gray-900 hover:bg-gray-800 focus:bg-yellow-800 focus:shadow-sm focus:ring-4 focus:ring-gray-800 focus:ring-opacity-50 text-white w-full py-4 text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block">
                <span class="inline-block mr-2">Acessar</span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4 inline-block">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                </svg>
              </button>
            </div>
            <div class="col-span-12 mt-8 text-center">
              <router-link to="/esqueceu-senha" class="mx-auto font-normal text-base text-gray-800 hover:text-blue-500 pb-4 block">Esqueci minha senha</router-link>
              <router-link  :style="{ 'background-color': colorSecundaria, 'color': invertColor(colorSecundaria, true) }" to="/registrar" class="mx-auto hover:text-blue-500 rounded-full transition duration-200 bg-gray-900 hover:bg-gray-800 focus:bg-yellow-800 focus:shadow-sm focus:ring-4 focus:ring-gray-800 focus:ring-opacity-50 text-white w-full py-4 text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block">Criar minha conta</router-link>
            </div>
            
          </div>
        </div>
        
    </div>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: 'Login',
  components: {
    VueRecaptcha
  },
  data: function () {
    return {
      option: 'consultor',
      visible: false,
      logo: null,

      imagem: "",
      texto: "",

      colorPrincipal: '#fbbf24',
      colorSecundaria: '#0097B2',
      form: {
        email: '',
        password: '',
        captcha: '',
        empresa: '',
      },
      siteConfig: null,
    }
  },
  methods: {
    // renderRecaptcha() {
    //   console.log("Render Captcha", window.grecaptcha)
    //   if(window.grecaptcha && window.grecaptcha.render) {
    //     window.grecaptcha.render(this.$refs.recaptcha, {
    //       sitekey: '6Lcn1d0pAAAAABXxaUhQbVSnHKv28J8j8ciuy_9F',
    //       callback: (response) => {
    //           console.log(response)
    //           this.form.captcha = response;
    //           this.$emit('verify', response);
    //           this.reset();
    //         }
    //     });
    //   }
    // },

    onCaptchaVerified(evento) {
      this.form.captcha = evento
    },
    onCaptchaExpired() {
      console.log("Captch Expirou")
      window.location.reload()
    },

    getUrl(option) {
      if(option === 'pessoas') return 'pessoas/login';
      return '/login'
    },
    getURL(){
      if(this.$route.query.profissional) return '/v1/profissionais/me';
      else if(this.$route.query.user) return '/v1/users/me_login';
      else return '/v1/pessoas/me'
    },
    async login() {
      //if(!this.form.captcha) return this.$vToastify.error("Verificação do CAPTCH inválido!");
      const resp = await this.$http.post(`${this.getUrl(this.option)}`, this.form);
      
      if (!resp.data.success) {
        this.$vToastify.error(resp.data.err);
      } else {
        this.err = false;

        await localStorage.setItem('token', resp.data.token);
        await localStorage.setItem('user', JSON.stringify(resp.data.user));
        this.$store.commit('login', resp.data.user);
        
        if(resp.data.empresa) {
          await this.$store.commit('setEmpresa', resp.data.empresa);
          await localStorage.setItem("empresa", JSON.stringify(resp.data.empresa));
          this.$store.commit('setColor', { colorPrincipal: resp.data.empresa.colorPrincipal, colorSecundaria: resp.data.empresa.colorSecundaria });
        }

        const reqnotificacoes = await this.$http.post(`/v1/notificacoes/countNotificacoes`);
        await this.$store.commit('setNotificacoes', reqnotificacoes.data);

        this.sockets.subscribe(this.$store.state.user._id.toString(), async function() {
          await this.$store.commit('setNotificacoes', this.$store.state.notificacoes + 1);
        });
        
        if(resp.data.user.cliente) {
          this.$router.push({path:'/projetos'});
        }else {
          this.$router.push({path:'/'});
        }
      }
    }
  },

  async mounted() {
    if(this.$route.query && this.$route.query.token) {
      try {
        localStorage.setItem('token', this.$route.query.token);
      
        const getme = await this.$http.post(`${this.getURL()}?token=${this.$route.query.token}`);

        if(!getme.data.pessoa) return this.$vToastify.error("URL de Login incorreta");

        localStorage.setItem('user', JSON.stringify(getme.data.pessoa));

        if(getme.data.empresa) {
          localStorage.setItem("empresa", JSON.stringify(getme.data.empresa));
          await this.$store.commit('setEmpresa', getme.data.empresa);
          this.$store.commit('setColor', { colorPrincipal: getme.data.empresa.colorPrincipal, colorSecundaria: getme.data.empresa.colorSecundaria });
        } else {
          localStorage.removeItem("empresa");
        }
        
        await this.$store.commit('login', getme.data.pessoa);

        if(getme.data.pessoa.cliente) {
          this.$router.push({path:'/projetos'});
        } else {
          this.$router.push({path:'/'});
        }
      } catch (error) {
        return this.$vToastify.error("URL de Login expirada, é necessário gerar uma nova");
      }
    
    }

    if(this.$store.state.isLogged && this.$store.state.user && this.$store.state.user._id){
      this.$router.push({path:'/'});
    }

    const hash = this.$route.params.hash;
    if(hash) {
      const reqEmpresa = await this.$http.get(`/get-empresa-hash/${hash}`);

      if(reqEmpresa.data){
        this.option = 'pessoas';

        this.form.empresa = reqEmpresa.data._id;

        if (reqEmpresa.data.logo && reqEmpresa.data.logo.filename) {
          this.logo = `${this.url_api}/upload?mimetype=image/jpeg&filename=${reqEmpresa.data.logo.filename}&folder=empresas`;
        }
        
        this.siteConfig = reqEmpresa.data.siteConfig;
        this.colorPrincipal = reqEmpresa.data.colorPrincipal;
        this.colorSecundaria = reqEmpresa.data.colorSecundaria;


        if (this.siteConfig.imagem?.filename) {
          this.imagem = `${this.url_api}/upload?mimetype=image/jpeg&filename=${this.siteConfig.imagem?.filename}&folder=empresas`
        } else {
          this.imagem = "/fundo-site-padrao.png";
        }

        if (this.siteConfig.texto) {
          this.texto = this.siteConfig.texto;
        } else {
          this.texto = "Dados pessoais são pessoas reais!";
        }
      }

      const reqUser = await this.$http.get(`/get-users-hash/${hash}`);
      if(reqUser.data){
        this.option = 'consultor';
        if(reqUser.data.logo && reqUser.data.logo.filename) this.logo = `${this.url_api}/upload?mimetype=image/jpeg&filename=${reqUser.data.logo.filename}&folder=empresas`;

        if (reqUser.data.siteConfig.imagem?.filename) {
          this.imagem = `${this.url_api}/upload?mimetype=image/jpeg&filename=${reqUser.data.siteConfig.imagem?.filename}&folder=empresas`
        } else {
          this.imagem = "/fundo-site-padrao.png";
        }

        if (reqUser.data.siteConfig.texto) {
          this.texto = reqUser.data.siteConfig.texto;
        } else {
          this.texto = "Dados pessoais são pessoas reais!";
        }

      }
    } else {

      const siteconfig = await this.$http.get("/configsite");

      if (siteconfig?.data?.siteConfig.imagem?.filename) {
        this.imagem = `${this.url_api}/upload?mimetype=image/jpeg&filename=${siteconfig.data.siteConfig.imagem?.filename}&folder=empresas`
      } else {
        this.imagem = "/fundo-site-padrao.png";
      }

      if (siteconfig?.data?.siteConfig.texto) {
        this.texto = siteconfig.data.siteConfig.texto;
      } else {
        this.texto = "Dados pessoais são pessoas reais!";
      }
    }
  },
}
</script>
