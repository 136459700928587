<template>
  <header>
    <div class="grid grid-cols-12">
      <div class="col-span-12 md:col-span-2">
        <div class="flex items-center justify-center w-full h-full relative py-2 md:py-0">
          <img v-if="$store.state.empresa && $store.state.empresa.logo && $store.state.empresa.logo.filename" class="h-12 2xl:h-16 object-cover" :src="`${url_api}/upload?mimetype=image/jpeg&filename=${$store.state.empresa.logo.filename}&folder=empresas`" />
          <a v-if="$store.state.empresa && !$store.state.empresa.logo.filename" href="#" class="text-base font-semibold tracking-widest text-gray-900  rounded-lg dark-mode:text-white focus:outline-none focus:shadow-outline">{{ $store.state.empresa.nome }}</a>
          <a v-if="!$store.state.empresa"  href="#" class="text-base font-semibold tracking-widest text-gray-900  rounded-lg dark-mode:text-white focus:outline-none focus:shadow-outline">Fácil LGPD</a>
          <button class="absolute right-3 md:hidden rounded-lg focus:outline-none focus:shadow-outline" @click="$store.commit('setOpenMobileNav')">
            <svg fill="currentColor" viewBox="0 0 20 20" class="w-7 h-7">
              <path v-show="!$store.state.openMobileNav" fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z" clip-rule="evenodd"></path>
              <path v-show="$store.state.openMobileNav" fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
            </svg>
          </button>
        </div>
      </div>
      <div class="col-span-3 flex items-center px-2 sm:px-6 lg:px-8" :style="{ 'background-color': $store.state.colorSecundaria }">
        <router-link to="/notificacoes" class="flex relative">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 md:h-7 md:w-7 text-white" :style="{ 'color': invertColor($store.state.colorSecundaria, true)}" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
          </svg>
          <span
            v-if="$store.state.notificacoes > 0"
            class="absolute -top-1 -right-4 flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-500 rounded-full"
            >{{ $store.state.notificacoes }}</span
          >
        </router-link>
      </div>
      <div class="col-span-9 md:col-span-7 px-2 sm:px-6 lg:px-8" :style="{ 'background-color': $store.state.colorSecundaria }">
        <div class="relative flex items-center justify-end h-16">
          <div class="mr-4 hidden md:inline">
            <router-link to="/assinatura" v-if="$store.state.user && $store.state.user.freeTrial" class="text-white font-r font-medium text-base hover:text-yellow-300" :style="{ 'color': invertColor($store.state.colorSecundaria, true)}">
              Dias de Teste Gratis: {{ $moment($store.state.user.data).add(15,'days').endOf('days').diff($moment().endOf('days'), 'days') > 0 ? $moment($store.state.user.data).add(15,'days').endOf('days').diff($moment().endOf('days'), 'days') : 'Finalizando' }}
            </router-link>
          </div>
          <div v-if="$store.state.user.consultor" class="float-right hidden md:flex items-center h-full">
            <img v-if="$store.state.user && $store.state.user.logo && $store.state.user.logo.filename" class="h-12 object-cover" :src="`${url_api}/upload?mimetype=image/jpeg&filename=${$store.state.user.logo.filename}&folder=empresas`" />
          </div>
          <router-link class="inline-flex px-3 py-2 rounded-md" :style="{ 'color': invertColor($store.state.colorSecundaria, true)}" :to="$store.state.user.cliente ? `/cadastros/pessoas/form/${$store.state.user._id}` : $store.state.user.profissional ? `/cadastros/profissionais/form/${$store.state.user._id}` : `/users/form/${$store.state.user._id}`">
              <img 
                v-if="$store.state.user && $store.state.user.foto && $store.state.user.foto.filename" 
                alt="icon-avatar"
                class="w-10 h-10 rounded-full object-cover"
                :src="`${url_api}/upload?mimetype=image/jpeg&filename=${$store.state.user.foto.filename}&folder=empresas`"
              />
              <img
                v-else
                src="@/assets/avatar.png"
                alt="icon-avatar"
                class="w-10 h-10 rounded-full object-cover"
              />
              <div class="block text-white hover:bg-gray-700 hover:text-yellow-400 text-sm_2 font-medium ml-3" :style="{ 'color': invertColor($store.state.colorSecundaria, true)}">
                <b>{{$store.state.user ? $store.state.user.name : ''}}</b>
                <br/>
                <small>Seu Perfil</small>
              </div>
          </router-link>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {};
  },
  methods: {},
};
</script>